import React, { ReactElement, useRef } from "react";
import img_card_background from "../images/baggrund.png";
import img_bus from "../images/bus.png";
import img_cube_copy from "../images/cube_copy.png";
import img_zafir from "../images/tykzafir.png";
import {
  default as img_wheel1,
  default as img_wheel2,
} from "../images/hjul.png";
import img_memory from "../images/memory2.png";
import { redirectToRandomSongUrl } from "../Services/GetRandomSong";
import RecipesButton from "./RecipesButton";
import { searchRandomAnimalImage } from "../Services/SearchRandomAnimalImage";

const MobileButtons = (): ReactElement => {
  const flipCardInner = useRef<HTMLDivElement>(null);
  const randomImg = useRef<HTMLImageElement>(null);
  const wheel1 = useRef<HTMLImageElement>(null);
  const wheel2 = useRef<HTMLImageElement>(null);
  const zafirImg = useRef<HTMLImageElement>(null);

  return (
    <>
      <div
        className="mobileButton"
        onClick={() => {
          if (flipCardInner !== null && flipCardInner.current !== null) {
            flipCardInner.current.style.transform = "rotateY(180deg)";
          }

          setTimeout(function () {
            window.location.href = "/memory";
          }, 1400);
        }}
      >
        <img src={img_memory} id="vendespilImg" className="memoryImg" />
        <div className="flip-card">
          <div className="flip-card-inner" ref={flipCardInner}>
            <div className="flip-card-front">
              <img
                src={img_card_background}
                alt="memory"
                className="cardBackgroundImg"
              />
            </div>
            <div className="flip-card-back"></div>
          </div>
        </div>
        <span className="buttonText memoryButtonText">Vendespil</span>
      </div>
      <div
        className="mobileButton"
        onClick={() => {
          if (randomImg.current !== null) {
            randomImg.current.style.transform = "rotate(360deg)";
          }
          setTimeout(function () {
            redirectToRandomSongUrl();
          }, 1400);
        }}
      >
        <img src={img_cube_copy} id="randomImg" ref={randomImg} />
        <span className="buttonText">Tilfældig børnesang</span>
      </div>
      <div
        className="mobileButton"
        onClick={() => {
          if (wheel1.current !== null) {
            wheel1.current.style.transform = "rotate(360deg)";
          }
          if (wheel2.current !== null) {
            wheel2.current.style.transform = "rotate(360deg)";
          }

          setTimeout(function () {
            window.location.href = "/hjulene_paa_bussen.html";
          }, 1400);
        }}
      >
        <img src={img_bus} id="busImg" />
        <img src={img_wheel1} id="hjul" ref={wheel1} />
        <img src={img_wheel2} id="hjul2" ref={wheel2} />
        <span className="buttonText wheelsOnTheBusText">Hjulene på bussen</span>
      </div>
      <RecipesButton />
      <div
        className="mobileButton"
        onClick={() => {
          if (zafirImg.current !== null) {
            zafirImg.current.style.transform = "rotate(360deg)";
          }
          setTimeout(function () {
            searchRandomAnimalImage();
          }, 1400);
        }}
      >
        <img src={img_zafir} id="zafirImg" ref={zafirImg} />
        <span className="buttonText">Drømmedyr</span>
      </div>
    </>
  );
};

export default MobileButtons;
