import Axios from "axios";

export interface GoogleSheetsType {
  range: string;
  majorDimension: string;
  values: string[][];
}

export const redirectToRandomSongUrl = (): void => {
  Axios.get(
    "https://sheets.googleapis.com/v4/spreadsheets/16PAqDwc0LKRGJxdOBm5ph6lCqTdl8-Zsm_tiz-oiaQg/values/Sheet1!A1:A1000?key=AIzaSyBBsG-WWI1oonDihmFsFZ_qf8hFGd5x5Ro"
  ).then((response) => {
    const googleSheet: GoogleSheetsType = response.data;
    const randomIndex = Math.floor(Math.random() * googleSheet.values.length);
    window.location.href = googleSheet.values[randomIndex][0];
  });
};
