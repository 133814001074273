import Axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GoogleDriveList } from "../MobilePage/MobilePage";
import Recipes from "../MobilePage/Recipes";
import Tabs from "../Tabs";
import MainBox from "./MainBox";

const FullPage = (): ReactElement => {
  const [googleDriveData, setGoogleDriveData] = useState<GoogleDriveList>();
  const [defaultTab, setDefaultTab] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url =
      "https://www.googleapis.com/drive/v3/files?q=%27" +
      "0B1uO2Sfa7zN3N2JvMmVSV1NoZE0" +
      "%27+in+parents&key=AIzaSyBBsG-WWI1oonDihmFsFZ_qf8hFGd5x5Ro";
    const config = {
      headers: {
        "X-Goog-Drive-Resource-Keys":
          "0B1uO2Sfa7zN3N2JvMmVSV1NoZE0/0-08wMZ75revZSVtvcLwZkoQ",
      },
    };
    setLoading(true);
    Axios.get(url, config)
      .then((response) => {
        //console.log("response.data", response.data);
        setGoogleDriveData(response.data);
        if (response.data.files[0]) {
          setDefaultTab(response.data.files[0].name);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <BrowserRouter>
        <MainBox />
        <Switch>
          <Route path="/recipes">
            {loading ? undefined : (
              <Tabs
                defaultTab={defaultTab}
                labels={googleDriveData?.files.map((s) => {
                  return s.name;
                })}
              >
                {googleDriveData?.files
                  ? googleDriveData.files.map((f) => (
                      <Recipes key={f.id} type={f.id} />
                    ))
                  : undefined}
              </Tabs>
            )}
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default FullPage;
