import React, { ReactElement } from "react";
import "./App.css";
import FullPage from "./FullPage/FullPage";
import MobilePage from "./MobilePage/MobilePage";

function App(): ReactElement {
  return (
    <>
      <FullPage />
      <MobilePage />
    </>
  );
}

export default App;
