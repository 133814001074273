import React, { ReactElement, useEffect, useState } from "react";
import Tab from "./Tab";

interface PropType {
  defaultTab?: string;
  labels?: string[];
  children?: ReactElement[];
}

const Tabs = (props: PropType): ReactElement<PropType> => {
  const [activeTab, setActiveTab] = useState(props.defaultTab);

  const onClickTabItem = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setActiveTab(props.defaultTab);
  }, [props.defaultTab]);

  const returnActiveChild = (): ReactElement | undefined => {
    if (props.labels) {
      for (let i = 0; i < props.labels.length; i++) {
        if (activeTab === props.labels[i]) {
          return props.children ? props.children[i] : undefined;
        }
      }
    }
    return undefined;
  };

  return (
    <div className="tabs">
      <div className="tabsContainer">
        <ol className="tab-list">
          {props.labels?.map((label) => {
            return (
              <Tab
                activeTab={activeTab ? activeTab : ""}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">{returnActiveChild()}</div>
      </div>
    </div>
  );
};

export default Tabs;
