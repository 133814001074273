import React, { ReactElement } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import MobileButtons from "./MobileButtons";
import Recipes from "./Recipes";

export interface File {
  kind: string;
  id: string;
  name: string;
  mimeType: string;
}

export interface GoogleDriveList {
  kind: string;
  incompleteSearch: boolean;
  files: File[];
}

const MobilePage = (): ReactElement => {
  return (
    <div className="mobile">
      <div
        className="lindro"
        onClick={() => {
          window.location.href = "/";
        }}
      ></div>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact={true}>
            <MobileButtons />
          </Route>
          <Route path="/recipes">
            <Recipes />
          </Route>
          <Route path="/products"></Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default MobilePage;
