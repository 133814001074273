import Axios from "axios";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import img_burger from "../images/burger.png";
import img_burger_eaten from "../images/burger_spist.png";
import { GoogleDriveList } from "./MobilePage";

const RecipesButton = (): ReactElement => {
  const recepies = useRef<HTMLImageElement>(null);
  const [recepiesOpen, setRecepiesOpen] = useState(false);
  const history = useHistory();

  const [googleDriveData, setGoogleDriveData] = useState<GoogleDriveList>();
  const [openHeight, setOpenHeight] = useState<number>(0);
  useEffect(() => {
    const url =
      "https://www.googleapis.com/drive/v3/files?q=%27" +
      "0B1uO2Sfa7zN3N2JvMmVSV1NoZE0" +
      "%27+in+parents&key=AIzaSyBBsG-WWI1oonDihmFsFZ_qf8hFGd5x5Ro";
    const config = {
      headers: {
        "X-Goog-Drive-Resource-Keys":
          "0B1uO2Sfa7zN3N2JvMmVSV1NoZE0/0-08wMZ75revZSVtvcLwZkoQ",
      },
    };
    Axios.get(url, config).then((response) => {
      setGoogleDriveData(response.data);
    });

    if (window.location.search === "?open=true") {
      setRecepiesOpen(true);
    }
  }, []);

  useEffect(() => {
    if (googleDriveData) {
      setOpenHeight(googleDriveData?.files.length * 41);
    }
  }, [googleDriveData]);

  return (
    <div
      ref={recepies}
      style={recepiesOpen ? { height: openHeight + "px" } : { height: "70px" }}
      className="mobileButton wrapper"
    >
      <div
        onClick={() => {
          setRecepiesOpen(!recepiesOpen);
        }}
      >
        <img
          src={recepiesOpen ? img_burger_eaten : img_burger}
          id="burgerImg"
        />
        <span className="buttonText">Opskrifter</span>
      </div>
      <div className="submenu">
        <ul className="menuItem">
          {googleDriveData?.files.map((s) => {
            return (
              <li key={s.id} onClick={() => history.push("/recipes/" + s.id)}>
                {s.name}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default RecipesButton;
