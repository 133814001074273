import Axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { GoogleDriveList } from "./MobilePage";
import img_folder from "../images/folder.png";
import img_doc from "../images/doc.png";
export interface IHash {
  [key: string]: string;
}

interface PropType {
  type?: string;
  folderId?: string;
}

const Recipes = (props: PropType): ReactElement<PropType> => {
  const [googleDriveData, setGoogleDriveData] = useState<GoogleDriveList>();
  const history = useHistory();
  const [stateChange, setStateChange] = useState(0);

  const [url, setUrl] = useState<string[]>();
  const [type, setType] = useState(props.type);
  const [lastFolder, setLastFolder] = useState<string | null>();

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    setUrl(pathname);
    if (pathname[2] || type) {
      const url =
        "https://www.googleapis.com/drive/v3/files?q=%27" +
        (type ? type : pathname[2]) +
        "%27+in+parents&key=AIzaSyBBsG-WWI1oonDihmFsFZ_qf8hFGd5x5Ro";

      Axios.get(url).then((response) => {
        setGoogleDriveData(response.data);
      });
    }
  }, [window.location.pathname, type, stateChange]);

  return (
    <div style={{ lineHeight: "30px", marginTop: "10px" }}>
      {lastFolder || !type ? (
        <div
          style={{ paddingLeft: "15px", cursor: "pointer" }}
          onClick={() => {
            if (type) {
              if (lastFolder) {
                setType(lastFolder);
                setLastFolder(null);
              }
            } else {
              if (url && url[3] !== undefined) {
                history.push("/recipes/" + url[3]);
                setStateChange(stateChange + 1);
              } else {
                history.push("/?open=true");
              }
            }
          }}
        >
          ...
        </div>
      ) : undefined}
      {googleDriveData?.files
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((f) => f.mimeType === "application/vnd.google-apps.folder")
        .map((f) => (
          <div
            key={f.id}
            onClick={() => {
              if (type) {
                setLastFolder(type);
                setType(f.id);
              } else {
                setStateChange(stateChange + 1);
                const pathname = window.location.pathname.split("/");
                history.push("/recipes/" + f.id + "/" + pathname[2]);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <table>
              <tbody>
                <tr>
                  <td style={{ verticalAlign: "middle" }}>
                    <img
                      src={img_folder}
                      style={{ padding: "10px", verticalAlign: "middle" }}
                      alt="folder"
                    />
                  </td>
                  <td> {f.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      {googleDriveData?.files
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((f) => f.mimeType !== "application/vnd.google-apps.folder")
        .map((f) => (
          <div key={f.id} style={{ cursor: "pointer" }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ verticalAlign: "middle" }}>
                    <img
                      style={{ padding: "10px", verticalAlign: "middle" }}
                      src={img_doc}
                      alt="doc"
                    />
                  </td>
                  <td>
                    {" "}
                    <a
                      href={"https://docs.google.com/document/d/" + f.id}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      {f.name}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
    </div>
  );
};

export default Recipes;
