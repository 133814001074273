//https://www.google.com/search?q=odder&sxsrf=AOaemvI-ypsv7Wa_CtZLjO1Bo-QTZzqa7g:1634335570448&source=lnms&tbm=isch&sa=X&ved=2ahUKEwj63vq4ts3zAhVLDuwKHYwiBXYQ_AUoAnoECAEQBA&biw=1536&bih=750&dpr=1.25

import Axios from "axios";

export interface GoogleSheetsType {
  range: string;
  majorDimension: string;
  values: string[][];
}

export const searchRandomAnimalImage = (): void => {
  Axios.get(
    "https://sheets.googleapis.com/v4/spreadsheets/184x2lTLu7LxyqU2K7kkAp1wRwfAIPc6fWqwKqX-iojQ/values/Ark1!A1:A1000?key=AIzaSyBBsG-WWI1oonDihmFsFZ_qf8hFGd5x5Ro"
  ).then((response) => {
    const googleSheet: GoogleSheetsType = response.data;
    const randomIndex = Math.floor(Math.random() * googleSheet.values.length);
    window.location.href =
      "https://www.google.com/search?q=" +
      googleSheet.values[randomIndex][0] +
      "&sxsrf=AOaemvI-ypsv7Wa_CtZLjO1Bo-QTZzqa7g:1634335570448&source=lnms&tbm=isch&sa=X&ved=2ahUKEwj63vq4ts3zAhVLDuwKHYwiBXYQ_AUoAnoECAEQBA&biw=1536&bih=750&dpr=1.25";
  });
};
