import React, { ReactElement } from "react";
import { useHistory } from "react-router";
import { redirectToRandomSongUrl } from "../Services/GetRandomSong";

const MainBox = (): ReactElement => {
  const history = useHistory();
  return (
    <div className="mainbox">
      <div className="lindro" onClick={() => history.push("/")}></div>

      <div
        className="button memory"
        onClick={() => {
          window.location.href = "/memory";
        }}
      >
        Vendespil
      </div>
      <div
        className="button random"
        id="element2"
        onClick={() => {
          redirectToRandomSongUrl();
        }}
      >
        Tilfældig børnesang
      </div>
      <div
        className="button bus"
        onClick={() => {
          window.location.href = "/hjulene_paa_bussen.html";
        }}
      >
        Hjulene på bussen
      </div>
      <div className="button burger" onClick={() => history.push("/recipes")}>
        Opskrifter
      </div>
    </div>
  );
};

export default MainBox;
